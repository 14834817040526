@import '../../design-tokens/variables.scss';

.why-us {
  width: 80vw;
  margin: 0 auto;
  padding: 10rem 0;

  &__container {
    h2 {
      margin: 0rem 3rem 6rem 3rem;
      text-align: center;
    }
    p {
      opacity: 0.6;
      margin-bottom: 3rem;
    }

    &__row {
      margin-bottom: 5rem;

      h3 {
        display: flex;
        align-items: center;
      }
      .icon {
        font-size: 5rem;

        fill: $color-red;
        /* filter: invert(19%) sepia(86%) saturate(2202%) hue-rotate(344deg) brightness(110%) contrast(98%); */
        margin-right: 2rem;
      }
      p {
        padding-left: 7rem;
      }
    }
  }
  &__image {
    position: relative;
    height: 20rem;
    img {
      opacity: 0.3;
      position: absolute;
      bottom: 0px;
    }
  }
}

@media only screen and (min-width: 500px) {
  .why-us {
    &__image {
      height: 30rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .why-us {
    &__image {
      height: 40rem;
    }
  }
}
@media only screen and (min-width: 880px) {
  .why-us {
    &__image {
      height: 50rem;
    }
  }
}

@media only screen and (min-width: 992px) {
  .why-us {
    display: grid;
    grid-template-columns: 3fr 2fr;
    &__image {
      height: auto;
      img {
        width: 55rem;
        right: -10rem;
        bottom: 4rem;
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  .why-us {
    &__image {
      img {
        width: 65rem;
        right: -15rem;
        bottom: 4rem;
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .why-us {
    &__image {
      img {
        width: 70rem;
        right: -15rem;
        bottom: 4rem;
      }
    }
  }
}
@media only screen and (min-width: 1700px) {
  .why-us {
    &__image {
      img {
        width: 90rem;
        right: -15rem;
        bottom: 0rem;
      }
    }
  }
}
