@import '../../design-tokens/variables.scss';

.schedule-view {
  width: 95%;
  margin: 2rem auto;
  animation-name: easyIn;
  animation-duration: 4s;
  h3 {
    opacity: 0.7;
  }
  &__time {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 2rem;

    p {
      display: block;
      padding: 1rem 0rem;
      border-top: 1px solid $color-gray;
      background-color: rgba($color: $color-gray, $alpha: 0.3);
      padding: 10px 5px;
      font-size: 1.4rem;
    }
  }

  &.active {
    animation-name: easyIn;
    animation-duration: 4s;
  }
}

@keyframes easyIn {
  from {
    opacity: 0;
  }
  to {
    to: 1;
  }
}
