@import '../../design-tokens/variables.scss';
.header {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #151515;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../../images/backgrounds/bg-01.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__quote {
    opacity: 0;
    width: 70vw;
    font-family: $font-avenirBlack;
    text-align: center;
    @include centerAbsolute;

    animation: logoAnimation 5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;

    & span {
      color: $color-red;
    }

    p {
      font-family: 'Open Sans', sans-serif;
      font-size: 2rem;
      font-weight: 200;
      opacity: 0.7;
      line-height: 2.5rem;
      margin-top: 2rem;
    }
  }
  &__subquote {
    font-size: 8rem !important;
    line-height: 7rem;
    color: rgba($color: $color-white, $alpha: 0.2);
    margin-top: 17rem;
  }
}

.logo {
  position: absolute;
  top: 3rem;
  left: 50%;
  transform: translate(-50%, -0%);
  width: 15rem;
  height: 15rem;
  opacity: 0.9;

  animation-name: logoAnimation;
  animation-duration: 3s;
  z-index: 100;
  & img {
    -webkit-filter: drop-shadow(
      0px 0px 30px rgba($color: $color-white, $alpha: 0.5)
    );
    filter: drop-shadow(0px 0px 30px rgba($color: $color-white, $alpha: 0.5));
  }
}

@keyframes logoAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.9;
  }
}

@media only screen and (min-width: 1200px) {
  .header {
    &__quote {
      font-size: 10rem;
      width: 80vw;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      p {
        font-family: 'Open Sans', sans-serif;
        font-size: 4rem;
        font-weight: 200;
        opacity: 0.7;
        line-height: 4.5rem;
        margin-top: 2rem;
      }
    }

    &__subquote {
      font-size: 14rem !important;
      line-height: 10rem;
      color: rgba($color: $color-white, $alpha: 0.2);
      margin-top: 12rem;
    }
  }
}
