@import '../../design-tokens/variables.scss';

.main-menu {
  font-size: 5rem;
  color: $color-white;

  &__icon {
    opacity: 0.8;
    position: absolute;
    right: 2rem;
    bottom: 4rem;
    cursor: pointer;
    z-index: 100;
    transition: all 0.4s ease-in-out;
    &.active {
      transform: rotate(90deg);
      opacity: 0.5;
    }
  }
  &__phone {
    opacity: 0.7;
    position: absolute;
    left: 2rem;
    bottom: 4rem;
    font-size: 4.5rem;
    cursor: pointer;
    z-index: 100;
    & a {
      color: rgba($color: $color-white, $alpha: 1);
    }
  }
  &__links {
    display: none;
    position: absolute;
    background-color: rgba($color: $color-black, $alpha: 1);
    padding: 20rem 2rem;
    width: 100vw;
    height: 100vh;
    margin: 0 auto;

    font-size: 3rem;
    text-align: center;
    z-index: 10;

    & li a {
      display: block;
      color: rgba($color: $color-white, $alpha: 0.3);
      letter-spacing: 1px;
      padding: 0.7rem 1rem;

      &:hover {
        color: rgba($color: $color-white, $alpha: 0.7);
        background-color: rgba($color: $color-white, $alpha: 0.1);
      }
    }

    &.active {
      display: block;
      animation-name: menuAnimation;
      animation-duration: 1s;
    }
  }
  &__social {
    display: none;
  }
}

@keyframes menuAnimation {
  from {
    opacity: 0;
    top: -5rem;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@media only screen and (min-width: 1432px) {
  .main-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__icon {
      display: none;
    }
    &__phone {
      display: none;
    }
    &__links {
      display: block;
      position: absolute;
      top: 7rem;
      left: 0;
      background-color: rgba($color: $color-red, $alpha: 0);
      padding: 2rem 4rem;
      height: 6rem;
      margin: 0 auto;

      font-size: 1.6rem;
      text-align: center;
      z-index: 10;

      display: flex;
      align-items: center;
      justify-content: flex-start;

      & li a {
        display: inline-block;
        color: rgba($color: $color-white, $alpha: 0.4);
        letter-spacing: 1px;
        padding: 1rem 2rem;

        &:hover {
          color: rgba($color: $color-white, $alpha: 0.9);
          background-color: rgba($color: $color-white, $alpha: 0);
        }
      }
    }
    &__social {
      position: absolute;
      top: 7rem;
      right: 0;
      background-color: rgba($color: $color-red, $alpha: 0);
      padding: 2rem 4rem;
      height: 6rem;
      margin: 0 auto;

      font-size: 2rem;
      text-align: center;
      z-index: 10;

      display: flex;
      align-items: center;
      justify-content: flex-end;

      & li a {
        display: inline-block;
        color: rgba($color: $color-white, $alpha: 0.6);
        letter-spacing: 1px;
        padding: 1rem 1.5rem;

        &:hover {
          color: rgba($color: $color-white, $alpha: 0.9);
          background-color: rgba($color: $color-white, $alpha: 0);
        }
      }
    }
  }

  .sticky {
    position: fixed;
    top: 0;
    width: 50vw;
    height: 6rem;
    background-color: $color-black;
  }
}
