@import '../../design-tokens/variables.scss';
.schedule-selector {
  display: flex;
  justify-content: center;

  &__btn {
    display: block;
    margin: 0.3rem;
    color: $color-white;
    font-size: 1.4rem;
    font-weight: 400;
    border: 2px solid $color-gray;

    line-height: 2rem;
    padding: 0.7rem 1.5rem;
    text-transform: uppercase;
    background: transparent;
    cursor: pointer;
    @include transitionEase;

    &:hover,
    &:active {
      border: 2px solid $color-red;
      color: $color-red;
      transform: translateY(1px);
    }
  }
}
