@import '../../design-tokens/variables.scss';

.about{
    width: 80vw;
    margin: 0 auto;
    padding: 10rem 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
        line-height: 3rem;
        opacity: 0.6;
    }

    &__desc{

        & h2 {
            margin: 6rem 0rem;
        }


        &__benefits{
            display: grid;
            grid-template-columns: repeat(1, 1fr);


            .benefit{
                padding: 2rem 1rem;

                & h3 {
                    display: flex;
                    align-items: center;
                    margin-bottom: 3rem;
                }

                &__icon {
                    color: $color-red;
                    font-size: 8rem;
                    margin-right: 1rem;
                }
               
            }
        }
    }

    &__form {
        width: 100%;
        margin: 0 auto;  
        
    }
}

@media only screen and (min-width: 768px) { 
    .about{
        
        &__form {
            max-width: 40rem;
            margin: 0 auto;  
        }
    }
}

@media only screen and (min-width: 992px) {
    .about{ 
       
        &__desc{
            &__benefits{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
    
    
            }
        }
    }
}
@media only screen and (min-width: 1200px) {
    .about{ 
        flex-direction: row;
        &__desc{
            &__benefits{
                display: grid;
                grid-template-columns: repeat(3, 1fr);
    
    
            }
        }
    }
}