/************************
*  VARIABLES
*
************************/

/* Fonts */
$font-avenir: 'Avenir';
$font-avenirBlack: 'AvenirBlack';
$font-helvetica: 'HelveticaNeue-roman';

/* Colors */


$color-white: #fff;
$color-red: #d22929;
$color-black: #151515;
$color-gray: #3a3942;

/* Margins */

$margin-title: 10rem 3rem 6rem 3rem;

/* Mixins */ 

@mixin containerWidth {
    width: 80vw;
}

@mixin centerTop {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%);
}

@mixin centerAbsolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin transitionEase {
    transition: all .3s ease;
}





.line {
    background-color: $color-gray;
    width: 100%;
    height: 1px;
    margin: 1rem 0;
  }

  /*************************
  *
  * BUTTONS
  *
  **************************/

  .btn {
    border: 2px solid #fff;
    height: 7rem;
    cursor: pointer;
    z-index: 2;
}

