* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(./fonts/AEH.ttf) format('truetype');
}
@font-face {
  font-family: 'HelveticaNeue-roman';
  src: local('HelveticaNeue-roman'),
    url(./fonts/HelveticaNeue-Roman.otf) format('truetype');
}
@font-face {
  font-family: 'AvenirBlack';
  src: local('AvenirBlack'), url(./fonts/AvenirBlack.ttf) format('truetype');
}
@import url(//fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);

@import './design-tokens/variables.scss';

body {
  //font-family: $font-helvetica, sans-serif;
  font-family: 'Open Sans', sans-serif;
  background-color: $color-black;
}

h1,
h2,
h3,
h4 {
  font-family: $font-avenir;
  color: $color-white;
  letter-spacing: -1px;
}
h1 {
  font-size: 7rem;
  line-height: 7rem;
  margin: 0;
}
h2 {
  font-size: 4rem;
}
h3 {
  font-size: 2.6rem;
}
h4 {
  font-size: 1.8rem;
}

p {
  color: $color-white;
  font-size: 1.6rem;
  letter-spacing: 1px;
  line-height: 2rem;
}

a {
  text-decoration: none;
}

img {
  width: 100%;
}

ul {
  list-style-type: none;
}

.App {
  overflow: hidden;
}
@media only screen and (min-width: 1200px) {
  h1 {
    font-size: 7rem;
    line-height: 7rem;
    margin: 0;
  }
  h2 {
    font-size: 6rem;
  }
  h3 {
    font-size: 3rem;
  }
  h4 {
    font-size: 2rem;
  }
}
