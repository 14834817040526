@import '../../design-tokens/variables.scss';

.scroll-to-top {
    position: fixed;
    right: 3rem;
    bottom: 3rem;
    z-index: 20;
        

        & div{
            width: 6rem;
            height: 6rem;
            border-radius: 50%;
            background-color: rgba($color: $color-red, $alpha: 0.5);
        
            font-size: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            @include transitionEase;
            
            &:hover {
                background-color: rgba($color: $color-red, $alpha: 0.8);
                color: rgba($color: $color-white, $alpha: 0.7);
            }
            &:active {
                transform: translateY(3px);
            }
        }
     
    }
   
