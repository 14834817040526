.schedule {
  padding: 10rem 0;
  h2 {
    text-align: center;
    margin-bottom: 3rem;
  }
}

@media only screen and (min-width: 900px) {
  .schedule {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    h2 {
      grid-column: 1/3;
    }

    .schedule-selector {
      grid-column: 1/3;
    }
  }
}
@media only screen and (min-width: 1024px) {
  .schedule {
    width: 90vw;
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
    h2 {
      grid-column: 1/4;
    }

    .schedule-selector {
      grid-column: 1/4;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .schedule {
    width: 80vw;
    margin: 0 auto;
  }
}
